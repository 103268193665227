import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logoBright from '../../assets/img/illustrations/brightLogo.png';
import vericastLogo from '../../assets/img/logos/vericastlogo.png';
import vericastLogoRetail from '../../assets/img/logos/vericastlogoRETAILPORTALa.png'
import viamediaLogoSandBox from '../../assets/img/logos/viaMEDIAlogoSANDBOX.png'
import viamediaLogo from'../../assets/img/logos/viaMEDIAlogo01.png'
import nCountrLogo from'../../assets/img/logos/NCOUNTR_LOGO.png'

const Logo = ({ at, width, className, ...rest }) => { 
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img
          className="mr-2"
          src={
            [1].includes(user?.role) && [87].includes(user?.client_id)
              ? vericastLogoRetail
              : [957, 953, 964, 963, 962, 993].includes(user?.user_id)
              ? vericastLogo
              : [1011, 1013, 1014].includes(user?.user_id)
              ? viamediaLogoSandBox
              : [1043].includes(user?.user_id)
              ? viamediaLogo
              : [1126].includes(user?.user_id)
              ? nCountrLogo
              : logoBright
          }
          alt="Logo"
          width={
            ([1].includes(user?.role) && [87].includes(user?.client_id)) ||
            [1011, 1013, 1014].includes(user?.user_id)
              ? 220
              : [1043].includes(user?.user_id)
              ? 125 
              : 100
          }
        />
        {/* <span className="text-sans-serif">falcon</span> */}
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
