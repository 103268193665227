import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Card, CardBody, CustomInput } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { hours, paymentByStatus } from '../../data/dashboard/payments';
import { rgbaColor, themeColors } from '../../helpers/utils';
import AppContext from '../../context/Context';
import { service } from '../../network/SuperGraph/service';

const PaymentsLineChart = () => {
  const [paymentStatus, setPaymentStatus] = useState('successful');
  const { isDark } = useContext(AppContext);
  let [originalDate, setDate] = useState([]);
  let [originalRevu, setRevu] = useState([]);


  useEffect(() => {
  
 

        //dates for graphs
        var end =new Date();
        end.setDate(end.getDate() + 1)
        var start = new Date();
        start.setDate(start.getDate() - 7)

        let fromDate = JSON.stringify(start)
        fromDate = fromDate.slice(1,11)
        let toDate = JSON.stringify(end)
        toDate = toDate.slice(1,11)

        console.log("from date is",fromDate,"toDate is",toDate);

        service.getGraph(fromDate,toDate).then(response => {
          var datas=response.data;
          var dateDetails = [];
          var revenueDetails =[];
          response.map((item, index) => {
            dateDetails.push(item.request_timestamp);
            revenueDetails.push(item.cpm);

        })   
        console.log("getGraphdetails",response);
             setDate(dateDetails);
             setRevu(revenueDetails);
        })
  }, []);


  const config = {
    data(canvas) {
      console.log("orginal date is",originalDate);
      console.log("setrevu",originalRevu);
      const ctx = canvas.getContext('2d');
      const gradientFill = isDark
        ? ctx.createLinearGradient(0, 0, 0, ctx.canvas.height)
        : ctx.createLinearGradient(0, 0, 0, 250);
      gradientFill.addColorStop(0, isDark ? 'rgba(44,123,229, 0.5)' : 'rgba(255, 255, 255, 0.3)');
      gradientFill.addColorStop(1, isDark ? 'transparent' : 'rgba(255, 255, 255, 0)');

      return {
        
        labels: originalDate.map(date => date),
        datasets: [
          {
            borderWidth: 2,
            data: originalRevu.map(d =>d.toFixed(2)),
            borderColor: rgbaColor(isDark ? themeColors.primary : '#fff', 0.8),
            backgroundColor: gradientFill
          }
        ]
      };
    },
    options: {
      legend: { display: false },
      tooltips: {
        mode: 'x-axis',
        xPadding: 20,
        yPadding: 10,
        displayColors: false,
        callbacks: {
          label: tooltipItem => `${originalDate[tooltipItem.index]} - ${tooltipItem.yLabel} USD`,
          title: () => null
        }
      },
      hover: { mode: 'label' },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              show: true,
              labelString: 'Month'
            },
            ticks: {
              fontColor: rgbaColor('#fff', 0.7),
              fontStyle: 600
            },
            gridLines: {
              color: rgbaColor('#fff', 0.1),
              zeroLineColor: rgbaColor('#fff', 0.1),
              lineWidth: 1
            }
          }
        ],
        yAxes: [
          {
            display: false,
            gridLines: {
              color: rgbaColor('#fff', 1)
            }
          }
        ]
      }
    }
  };

  return (
    <Card className="mb-3">
      <CardBody className="rounded-soft bg-gradient">
        <Row className="text-white align-items-center no-gutters">
          <Col>
            <h4 className="text-white mb-0">Performance Activities</h4>
          </Col>
        </Row>
        <Line data={config.data} options={config.options} width={1618} height={375} />
      </CardBody>
    </Card>
  );
};

export default PaymentsLineChart;
