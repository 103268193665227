const axios = require("axios");
const qs = require("querystring");

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
  },
};
function clientListAll() {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/client/list",
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function roleFilter(role_id) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      role_id: role_id,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/user/allRoles",
      customConfig
    )
    .then((response) => {
      console.log("role user response =", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function usernameFilter(client_id) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      client_id: client_id,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/organization/list",
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function locationFilter(client_id, orgid) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      client_id: client_id,
      organizaton_id: orgid,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/location/list",
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function locationFilter2(client_id) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      client_id: client_id,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/location/list",
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function locationFilterOrg(client_id, org_id) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      client_id: client_id,
      organization_id: org_id,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/location/list",
      customConfig
    )
    .then((response) => {
      console.log("user response location filter=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function userFilter(client_id, org_id, loc_id, role_id,orgRole) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      client_id: client_id,
      role_id: role_id,
      location_id: loc_id,
      organizaton_id: org_id,
      orgRole:orgRole
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/user/filter",
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function userListAll(roleid, clientid, userid, orgId) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      role_id: roleid,
      client_id: clientid,
      user_id: userid,
      org_id: orgId,
    },
  };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + "/v1/user/all", customConfig)
    .then((response) => {
      console.log("user list all", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function userListAll2(roleid, userid) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      role_id: roleid,
      user_id: userid,
    },
  };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + "/v1/user/all", customConfig)
    .then((response) => {
      console.log("user list all", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function roleAll() {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/user/allRoles",
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
function usernameAll() {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/organization/all",
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function locationAll() {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/location/byOrganization",
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
function clientAll() {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
  };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + "/v1/client/all", customConfig)
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function clientChange(clientId) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
  };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + "/v1/client/all", customConfig)
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function clientChangeUser(clientId) {
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log("user=",user);
  const requestBody = {
    client_id: clientId,
  };
  // console.log("req=",requestBody);
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": user.token,
    },
  };
  return axios
    .post(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/clientChange",
      qs.stringify(requestBody),
      customConfig
    )
    .then((response) => {
      // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function organisationChangeUser(clientId) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      client_id: clientId,
      // organization_id :orgId
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/location/list",
      customConfig
    )
    .then((response) => {
      // console.log(response,'response edit details');
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function advertiserList() {
  var role_id = localStorage.getItem("role_id");
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      role_id: role_id,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/advertiser/list",
      customConfig
    )
    .then((response) => {
      console.log("user response campaign filter=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function advertiserList1(clientid) {
  var role_id = localStorage.getItem("role_id");
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      role_id: role_id,
      client_id: clientid,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/advertiser/list",
      customConfig
    )
    .then((response) => {
      console.log("user response campaign filter=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function userAdd(
  password,
  name,
  username,
  location,
  role,
  client,
  user_id,
  phone,
  email_id,
  status,
  approver,
  approver_required,
  advertiser,
  mfaFlag,
  mfa_type,
  country_dial_code
) {
  var requestBody = {};
  console.log("userAdduserAdd", client);
  if (client == "" || client == null) {
    requestBody = {
      advertiser_id: advertiser,
      password: password,
      name: name,
      userid: user_id,
      location_id: location,
      role_id: role,
      org_id: username,
      phone: phone,
      email: email_id,
      status: "1",
      approver: approver,
      approve_required: approver_required,
    };
  } else {
    requestBody = {
      advertiser_id: advertiser,
      password: password,
      name: name,
      client_id: client,
      userid: user_id,
      location_id: location,
      role_id: role,
      org_id: username,
      phone: phone,
      email: email_id,
      status: "1",
      approver: approver,
      approve_required: approver_required,
    };
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": user.token,
    },
  };
  requestBody.mfa_enabled_flag = mfaFlag === true ? 1 : 0;
  requestBody.mfa_type = mfa_type;
  requestBody.country_calling_code = country_dial_code;
  
  console.log("req=", requestBody);
  return axios
    .post(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/user/add",
      qs.stringify(requestBody),
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);

      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function updateUser(
  id,
  password,
  name,
  username,
  location,
  role,
  client,
  user_id,
  userid,
  phone,
  email_id,
  status,
  approver,
  approver_required,
  advertiser,
  mfaFlag,
  mfa_type,
  country_dial_code
) {
  var requestBody = {};
  if (client == "" || client == null) {
    if (password == "" || password == null) {
      requestBody = {
        advertiser_id: advertiser,
        id: id,
        name: name,
        userid: userid,
        user_id: user_id,
        location_id: location,
        role_id: role,
        org_id: username,
        phone: phone,
        email: email_id,
        status: "1",
        approver: approver,
        approve_required: approver_required,
      };
    } else {
      requestBody = {
        advertiser_id: advertiser,
        id: id,
        password: password,
        name: name,
        userid: userid,
        user_id: user_id,
        location_id: location,
        role_id: role,
        org_id: username,
        phone: phone,
        email: email_id,
        status: "1",
        approver: approver,
        approve_required: approver_required,
      };
    }
  } else {
    if (password == "" || password == null) {
      requestBody = {
        advertiser_id: advertiser,
        id: id,
        name: name,
        client_id: client,
        userid: userid,
        user_id: user_id,
        location_id: location,
        role_id: role,
        org_id: username,
        phone: phone,
        email: email_id,
        status: "1",
        approver: approver,
        approve_required: approver_required,
      };
    } else {
      requestBody = {
        advertiser_id: advertiser,
        id: id,
        password: password,
        name: name,
        client_id: client,
        userid: userid,
        user_id: user_id,
        location_id: location,
        role_id: role,
        org_id: username,
        phone: phone,
        email: email_id,
        status: "1",
        approver: approver,
        approve_required: approver_required,
      };
    }
  }
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": user.token,
    },
  };
  requestBody.mfa_enabled_flag = mfaFlag === true ? 1 : 0;
  requestBody.mfa_type = mfa_type;
  requestBody.country_calling_code = country_dial_code;

  
  console.log("debug", "debug==:", requestBody);
  return axios
    .post(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/user/update",
      qs.stringify(requestBody),
      customConfig
    )
    .then((response) => {
      console.log("user response=", response.data);
      if (response.data.status == 100) {
        return response;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function userEdit(user_id) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      user_id: user_id,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/user/details",
      customConfig
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function deviceFilter(client_id, organization_id, location_id) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      client_id: client_id,
      organization_id: organization_id,
      location_id: location_id,
    },
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/device/list",
      customConfig
    )
    .then((response) => {
      // console.log(response,'response edit details');
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function deleteUser(user_id) {
  const requestBody = {
    user_id: user_id,
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": user.token,
    },
  };
  console.log("req=", requestBody);
  return axios
    .post(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/user/delete",
      qs.stringify(requestBody),
      customConfig
    )
    .then((response) => {
      console.log("user response=", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function isEmailIdExist(email) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      email: email,
    },
  };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + "/v1/validate", customConfig)
    .then((response) => {
      // console.log(response,'response edit details');
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function isUserIdExists(userid) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    },
    params: {
      user_id: userid,
    },
  };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + "/v1/validate", customConfig)
    .then((response) => {
      // console.log(response,'response edit details');
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function resetPassword(userid,password) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token,
    }
  };
  let requestBody={user_id:userid,password:password}
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + "/v1/user/resetpassword",qs.stringify(requestBody),customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
// eslint-disable-next-line
export const service = {
  clientListAll,
  userAdd,
  userEdit,
  deleteUser,
  userListAll,
  userListAll2,
  updateUser,
  roleAll,
  roleFilter,
  clientAll,
  deviceFilter,
  usernameAll,
  usernameFilter,
  userFilter,
  locationAll,
  locationFilter,
  locationFilterOrg,
  locationFilter2,
  clientChange,
  clientChangeUser,
  organisationChangeUser,
  isEmailIdExist,
  isUserIdExists,
  advertiserList,
  advertiserList1,
  resetPassword
};
