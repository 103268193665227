import { version } from "./config";
import { ORGANIZATION_LABEL } from "./components/bootstrap-components/GlobalVariables/globalvariable";


const user = JSON.parse(localStorage.getItem("user"));
const advertiser_id = localStorage.getItem("advertiser_id"); //for hiding dashboard screen for pepsi advertiser

export let homeRoutes = {};
export let videoRoutes = {};
export let deviceRoutes = {};
export let clientRoutes = {};
export let outageRoutes = {};
export let demandRoutes = {};
export let demandPartnerRoutes = {};
export let demandSourceRoutes = {};
export let playlistRoutes = {};
export let campaignRoutes = {};
export let creativeManagementRoutes = {}
export let superReportRoutes = {};
export let demandReportRoutes = {};
export let merchantRoutes = {};
export let monthlyRoutes = {};
export let userNameRoutes = {};
export let emailsRoutes = {};
export let locationRoutes = {};
export let userManagementRoutes = {};
export let userDetailsRoutes = {};
export let settingsRoutes = {};
export let importRoutes = {};
export let pageRoutes = {};
export let chatRoutes = {};
export let emailRoutes = {};
export let authenticationRoutes = {};
export let ECommerceRoutes = {};
export let widgetsRoutes = {};
export let componentRoutes = {};
export let utilityRoutes = {};
export let pluginRoutes = {};
export let documentationRoutes = {};
export let changelogRoutes = {};
export let outagereportroutes = {};
export let advertiserroutes = {};
export let revenueanalyticsreportroutes = {};
export let partnerdetailsroutes = {};
export let integrationroutes = {};
export let healthreportroutes = {};
export let templateroutes = {};
export let rssfeedroutes = {};
export let bannerroutes = {};
export let display = {};
export let displayCampaignRoutes = {};
export let displayDemandRoutes = {};
export let audienceTargetingRoute = {};
export let videoAnalyticsReport = {};
export let ServiceConfigurationRoutes ={};
export let placeExchangeLocationSearch = {};
export let reconciliationRoutes = {};


let demographicRoutes = {};


if (user) {
  if (user.role === 26 && user.user_id === 1154) {
    homeRoutes = {
      name: "Home",
      to: "/",
      exact: true,
      icon: "home",
      children: [
        {
          to: "/",
          name: "Dashboard",
          icon: "chart-pie",
          exact: true,
        },
      ],
    };
    videoRoutes = {
      name: "Videos",
      to: "/components",
      icon: "video",
      children: [
        { to: "/videos", name: "List" },
      ],
    };
    deviceRoutes = {
      name: "Devices",
      to: "/components",
      icon: "tv",
      children: [
        { to: "/components/deviceList", name: "List" },
        { to: "/components/deviceAdd", name: "Add New" },
        { to: "/components/device/enquiry", name: "Inquiry"}
      ],
    };

    clientRoutes = {
      name: "Clients",
      to: "/components",
      icon: "user",
      children: [
        { to: "/components/clientList", name: "List" },
        { to: "/components/clientAdd", name: "Add New" },
      ],
    };

    demandRoutes = {
      name: "Demand Manage",
      to: "/components",
      icon: "ad",
      children: [
        { to: "/components/demandSourceList", name: "Demand List" },
        { to: "/components/demandPartnerList", name: "Partner List" },
        { to: "/demand/mapping", name: "Demand Mapping" },
        { to: "/components/rtb/mapping", name: "RTB Mapping" },
      ],
    };

    revenueanalyticsreportroutes = {
      name: "Revenue Analytics ",
      to: "/components/revenueanalyticsreport",
      icon: "file-invoice-dollar",
    };

    demandPartnerRoutes = {
      name: "Demand Partner",
      to: "/components",
      icon: "users",
      children: [
        { to: "/components/demandPartnerList", name: "List" },
        { to: "/components/demandPartnerAdd", name: "Add New" },
      ],
    };

    campaignRoutes = {
      name: "Video Campaigns",
      to: "/components",
      icon: "play",
      children: [
        { to: "/components/campaignList", name: "List" },
        { to: "/components/campaignReport", name: "Report" },
        {to: "/components/combinedReport", name: "Granular Report"},
        {
          name :"Plan Search",
          to : "/components/planSearch",
          icon : "search",
        },
        // { to: "/components/campaignReport/new", name: "Report New" },
        { to: "/components/placeExchange", name: " Place Exchange Report"},
        (audienceTargetingRoute = {
          name: "Audience Target",
          to: "/components",
          icon: "lock",
          children: [
            {
              to: "/components/campaign/audienceTarget/add",
              name: "Add",
            },
            {
              to: "/components/campaign/audienceTarget/list",
              name: "List",
            },
          ],
        }),
        (advertiserroutes = {
          name: "Advertiser",
          to: "/components",
          icon: "lock",
          children: [
            {
              to: "/components/CampaignPaymentAdmn",
              name: "Advertiser Payment",
            },
            {
              to: "/components/advertiserstatement",
              name: "Advertiser Statement",
            },
            { to: "/components/paymentReport", name: "Payment Details" },
          ],
        }),
      ],
    };

    creativeManagementRoutes = {
      name :"Creative Management",
      to : "/components",
      icon : "photo-video",
      children : [
        {to : "/components/creativeManageList", name : "List"},
        {to : "/components/creativeManageAdd", name : "Add New"},
      ],
    }

    superReportRoutes = {
      name: "Super Admin Report",
      to: "/components/superReport",
      icon: "user-tie",
    };

    demandReportRoutes = {
      name: "Demand Report",
      to: "/components/demandReport",
      icon: "poll",
    };

    userNameRoutes = {
      name: "Network",
      to: "/components",
      icon: "cubes",
      children: [
        { to: "/components/userNameList", name: "List" },
        { to: "/components/userNameAdd", name: "Add New" },
      ],
    };

    locationRoutes = {
      name: "Locations",
      to: "/components",
      icon: "map-marked",
      children: [
        { to: "/components/locationList", name: "List" },
        { to: "/components/locationAdd", name: "Add New" },
      ],
    };

    userManagementRoutes = {
      name: "User Manage",
      to: "/components",
      icon: "book",
      children: [
        { to: "/components/clientList", name: "List" },
        { to: "/components/clientAdd", name: "Add User" },
      ],
    };

    userDetailsRoutes = {
      name: "User Manage",
      to: "/components",
      icon: "users-cog",
      children: [
        { to: "/components/userList", name: "List" },
        { to: "/components/userAdd", name: "Add User" },
      ],
    };
  } else if (user.role === 26) {
    homeRoutes = {
      name: "Home",
      to: "/",
      exact: true,
      icon: "home",
      children: [
        {
          to: "/",
          name: "Dashboard",
          icon: "chart-pie",
          exact: true,
        },
      ],
    };
    healthreportroutes = {
      name: "Health Report",
      to: "/components/healthreports",
      icon: "file-medical",
    };
    partnerdetailsroutes = {
      name: " Intel Partner Details",
      to: "/components/Partnerdetails",
      icon: "poll",
    };
    videoRoutes = {
      name: "Videos",
      to: "/components",
      icon: "video",
      children: [
        { to: "/videos", name: "List" },
      ],
    };
    integrationroutes = {
      name: "Integration",
      to: "/components",
      icon: "copy",
      children: [
        { to: "/components/integrationadd", name: "Integration Add" },
        { to: "/components/integrationlist", name: "Integration List" },
      ],
    };
    deviceRoutes = {
      name: "Devices",
      to: "/components",
      icon: "tv",
      children: [
        { to: "/components/deviceList", name: "List" },
        { to: "/components/deviceAdd", name: "Add New" },
        { to: "/components/device/enquiry", name: "Inquiry"}
      ],
    };

    clientRoutes = {
      name: "Clients",
      to: "/components",
      icon: "user",
      children: [
        { to: "/components/clientList", name: "List" },
        { to: "/components/clientAdd", name: "Add New" },
      ],
    };

    demandRoutes = {
      name: "Demand Manage",
      to: "/components",
      icon: "ad",
      children: [
        { to: "/components/demandSourceList", name: "Demand List" },
        { to: "/components/demandPartnerList", name: "Partner List" },
        { to: "/demand/mapping", name: "Demand Mapping" },
        { to: "/components/rtb/mapping", name: "RTB Mapping" },
      ],
    };

    ServiceConfigurationRoutes = {
      name: "Service Configuration",
      to: "/components",
      icon:"cog",
      children: [
        { to: "/components/ServiceConfiguration", name: "List" }
      ]
    }

    revenueanalyticsreportroutes = {
      name: "Revenue Analytics ",
      to: "/components/revenueanalyticsreport",
      icon: "file-invoice-dollar",
    };

    demandPartnerRoutes = {
      name: "Demand Partner",
      to: "/components",
      icon: "users",
      children: [
        { to: "/components/demandPartnerList", name: "List" },
        { to: "/components/demandPartnerAdd", name: "Add New" },
      ],
    };

    campaignRoutes = {
      name: "Video Campaigns",
      to: "/components",
      icon: "play",
      children: [
        { to: "/components/campaignList", name: "List" },
        { to: "/components/campaignReport", name: "Report" },
        {to: "/components/combinedReport", name: "Granular Report"},
        {
          name :"Plan Search",
          to : "/components",
          icon : "search",
          children:[
            {to: "/components/planning/planSearchNew", name: "Search plan"}
          ]
        },
        (audienceTargetingRoute = {
          name: "Audience Target",
          to: "/components",
          icon: "lock",
          children: [
            {
              to: "/components/campaign/audienceTarget/add",
              name: "Add",
            },
            {
              to: "/components/campaign/audienceTarget/list",
              name: "List",
            },
          ],
        }),
        (advertiserroutes = {
          name: "Advertiser",
          to: "/components",
          icon: "lock",
          children: [
            {
              to: "/components/CampaignPaymentAdmn",
              name: "Advertiser Payment",
            },
            {
              to: "/components/advertiserstatement",
              name: "Advertiser Statement",
            },
            { to: "/components/paymentReport", name: "Payment Details" },
          ],
        }),
      ],
    };

    creativeManagementRoutes = {
      name :"Creative Management",
      to : "/components",
      icon : "photo-video",
      children : [
        {to : "/components/creativeManageList", name : "List"},
        {to : "/components/creativeManageAdd", name : "Add New"},
      ],
    }

    superReportRoutes = {
      name: "Super Admin Report",
      to: "/components/superReport",
      icon: "user-tie",
    };

    outagereportroutes = {
      name: "Outage Report",
      to: "/components/outagereport",
      icon: "lock",
    };

    merchantRoutes = {
      name: "Merchant Statement",
      to: "/components/merchantStatement",
      icon: "copy",
    };

    demandReportRoutes = {
      name: "Demand Report",
      to: "/components/demandReport",
      icon: "poll",
    };

    userNameRoutes = {
      name: ORGANIZATION_LABEL,
      to: "/components",
      icon: "cubes",
      children: [
        { to: "/components/userNameList", name: "List" },
        { to: "/components/userNameAdd", name: "Add New" },
      ],
    };

    locationRoutes = {
      name: "Locations",
      to: "/components",
      icon: "map-marked",
      children: [
        { to: "/components/locationList", name: "List" },
        { to: "/components/locationAdd", name: "Add New" },
      ],
    };

    userManagementRoutes = {
      name: "User Manage",
      to: "/components",
      icon: "book",
      children: [
        { to: "/components/clientList", name: "List" },
        { to: "/components/clientAdd", name: "Add User" },
      ],
    };

    userDetailsRoutes = {
      name: "User Manage",
      to: "/components",
      icon: "users-cog",
      children: [
        { to: "/components/userList", name: "List" },
        { to: "/components/userAdd", name: "Add User" },
      ],
    };

    settingsRoutes = {
      name: "Settings",
      to: "/components",
      icon: "code-branch",
      children: [
        { to: "/components/clientList", name: "Reset Password" },
        { to: "/components/clientAdd", name: "Offline Video" },
      ],
    };

    authenticationRoutes = {
      name: "Authentication",
      to: "/authentication",
      icon: "lock",
      children: [
        {
          to: "/authentication/basic",
          name: "Basic",
          children: [
            { to: "/authentication/basic/login", name: "Login" },
            { to: "/authentication/basic/logout", name: "Logout" },
            { to: "/authentication/basic/register", name: "Register" },
            {
              to: "/authentication/basic/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/basic/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/basic/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/basic/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/card",
          name: "Card",
          children: [
            { to: "/authentication/card/login", name: "Login" },
            { to: "/authentication/card/logout", name: "Logout" },
            { to: "/authentication/card/register", name: "Register" },
            {
              to: "/authentication/card/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/card/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/card/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/card/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/split",
          name: "Split",
          children: [
            { to: "/authentication/split/login", name: "Login" },
            { to: "/authentication/split/logout", name: "Logout" },
            { to: "/authentication/split/register", name: "Register" },
            {
              to: "/authentication/split/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/split/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/split/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/split/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/wizard",
          name: "Wizard",
        },
      ],
    };

    ECommerceRoutes = {
      name: "E commerce",
      to: "/e-commerce",
      icon: "cart-plus",
      children: [
        { to: "/e-commerce/products/list", name: "Product list" },
        { to: "/e-commerce/products/grid", name: "Product grid" },
        { to: "/e-commerce/product-details", name: "Product details" },
        { to: "/e-commerce/orders", name: "Orders" },
        { to: "/e-commerce/order-details", name: "Order details" },
        { to: "/e-commerce/customers", name: "Customers" },
        { to: "/e-commerce/shopping-cart", name: "Shopping cart" },
        { to: "/e-commerce/checkout", name: "Checkout" },
        { to: "/e-commerce/favourite-items", name: "Favourite items" },
      ],
    };

    pageRoutes = {
      name: "Pages",
      to: "/pages",
      icon: "copy",
      children: [
        { to: "/pages/activity", name: "Activity" },
        { to: "/pages/associations", name: "Associations" },
        { to: "/pages/billing", name: "Billing" },
        { to: "/pages/customer-details", name: "Customer details" },
        { to: "/pages/event-detail", name: "Event detail" },
        { to: "/pages/event-create", name: "Event create" },
        { to: "/pages/events", name: "Events" },
        { to: "/pages/faq", name: "Faq" },
        { to: "/pages/invoice", name: "Invoice" },
        { to: "/pages/invite-people", name: "Invite people" },
        { to: "/pages/notifications", name: "Notifications" },
        { to: "/pages/people", name: "People" },
        { to: "/pages/pricing", name: "Pricing" },
        { to: "/pages/pricing-alt", name: "Pricing alt" },
        { to: "/pages/profile", name: "Profile" },
        { to: "/pages/settings", name: "Settings" },
        { to: "/pages/starter", name: "Starter" },
        {
          to: "/errors",
          name: "Errors",
          children: [
            { to: "/errors/404", name: "404" },
            { to: "/errors/500", name: "500" },
          ],
        },
      ],
    };
    widgetsRoutes = {
      name: "Widgets",
      to: "/widgets",
      exact: true,
      icon: "poll",
      badge: {
        text: `New`,
        color: "soft-success",
      },
    };

    chatRoutes = {
      name: "Chat",
      to: "/chat",
      exact: true,
      icon: "comments",
    };

    emailRoutes = {
      name: "Email",
      to: "/email",
      icon: "envelope-open",
      children: [
        { to: "/email/inbox", name: "Inbox" },
        { to: "/email/email-detail", name: "Email detail" },
        { to: "/email/compose", name: "Compose" },
      ],
    };

    documentationRoutes = {
      name: "Documentation",
      to: "/documentation",
      exact: true,
      icon: "book",
    };

    changelogRoutes = {
      name: "ChangeLog",
      to: "/changelog",
      exact: true,
      icon: "code-branch",
      badge: {
        text: `v${version}`,
        color: "soft-primary",
      },
    };

    componentRoutes = {
      name: "Clients",
      to: "/components",
      icon: "puzzle-piece",
      children: [
        { to: "/components/alerts", name: "Alerts" },
        { to: "/components/accordions", name: "Accordions" },
        { to: "/components/avatar", name: "Avatar" },
        { to: "/components/badges", name: "Badges" },
        { to: "/components/backgrounds", name: "Backgrounds" },
        { to: "/components/breadcrumb", name: "Breadcrumb" },
        { to: "/components/buttons", name: "Buttons" },
        { to: "/components/cards", name: "Cards" },
        { to: "/components/collapses", name: "Collapses" },
        {
          to: "/components/carousel",
          name: "Carousel",
          badge: {
            text: `New`,
          },
        },
        { to: "/components/dropdowns", name: "Dropdowns" },
        { to: "/components/forms", name: "Forms" },
        { to: "/components/listgroups", name: "List groups" },
        { to: "/components/modals", name: "Modals" },
        { to: "/components/navs", name: "Navs" },
        { to: "/components/navbars", name: "Navbars" },
        {
          to: "/components/navbar-top",
          name: "Navbar Top",
          badge: {
            text: `New`,
          },
        },
        { to: "/components/pageheaders", name: "Page headers" },
        { to: "/components/paginations", name: "Paginations" },
        { to: "/components/popovers", name: "Popovers" },
        { to: "/components/progress", name: "Progress" },
        {
          to: "/components/sidepanel",
          name: "Sidepanel",
          badge: {
            text: "New",
          },
        },
        {
          to: "/components/spinners",
          name: "Spinners",
          badge: {
            text: `New`,
          },
        },

        { to: "/components/tables", name: "Tables" },
        { to: "/components/clientList", name: "Client List" },
        { to: "/components/clientAdd", name: "Client Add" },
        { to: "/components/clientEdit", name: "Client Edit" },
        { to: "/components/tooltips", name: "Tooltips" },
      ],
    };
    pluginRoutes = {
      name: "Plugins",
      to: "/plugins",
      icon: "plug",
      children: [
        { to: "/plugins/bulk-select", name: "Bulk select" },
        {
          to: "/plugins",
          name: "Chart",
          children: [
            { to: "/plugins/chart", name: "Chart Js" },
            { to: "/plugins/echarts", name: "Echarts" },
          ],
        },
        { to: "/plugins/countup", name: "Countup" },
        { to: "/plugins/code-highlight", name: "Code Highlight" },

        { to: "/plugins/datetime", name: "Datetime" },
        { to: "/plugins/dropzone", name: "Dropzone" },
        { to: "/plugins/emoji-mart", name: "Emoji Mart" },
        { to: "/plugins/fontawesome", name: "Fontawesome" },

        { to: "/plugins/image-lightbox", name: "Image lightbox" },
        { to: "/plugins/lottie", name: "Lottie" },
        {
          to: "/plugins",
          name: "Map",
          children: [
            { to: "/plugins/leaflet-map", name: "Leaflet map" },
            { to: "/plugins/google-map", name: "Google map" },
            { to: "/plugins/echart-map", name: "Echart Map" },
          ],
        },
        { to: "/plugins/plyr", name: "Plyr" },
        { to: "/plugins/progressbar", name: "Progressbar" },
        { to: "/plugins/react-hook-form", name: "React Hook Form" },
        { to: "/plugins/select", name: "Select" },
        { to: "/plugins/slick-carousel", name: "Slick Carousel" },
        { to: "/plugins/scroll-bar", name: "Scroll Bar" },
        { to: "/plugins/toastify", name: "Toastify" },
        { to: "/plugins/typed", name: "Typed" },
        { to: "/plugins/wysiwyg", name: "WYSIWYG editor" },
      ],
    };

    utilityRoutes = {
      name: "Utilities",
      to: "/utilities",
      icon: ["fab", "hotjar"],
      children: [
        { to: "/utilities/borders", name: "Borders" },
        { to: "/utilities/clearfix", name: "Clearfix" },
        { to: "/utilities/closeIcon", name: "Close icon" },
        { to: "/utilities/colors", name: "Colors" },
        { to: "/utilities/display", name: "Display" },
        { to: "/utilities/embed", name: "Embed" },
        { to: "/utilities/figures", name: "Figures" },
        { to: "/utilities/flex", name: "Flex" },
        { to: "/utilities/grid", name: "Grid" },
        { to: "/utilities/sizing", name: "Sizing" },
        { to: "/utilities/spacing", name: "Spacing" },
        { to: "/utilities/stretchedLink", name: "Stretched link" },
        { to: "/utilities/typography", name: "Typography" },
        { to: "/utilities/verticalAlign", name: "Vertical align" },
        { to: "/utilities/visibility", name: "Visibility" },
      ],
    };
    templateroutes = {
      name: "Template",
      to: "/components",
      icon: "copy",
      children: [
        { to: "/components/templateadd", name: "Template Add" },
        { to: "/components/templatelist", name: "Template List" },
        { to: "/components/createtheme", name: "Theme Create" },
        { to: "/components/themelist", name: "Theme List" },
        (bannerroutes = {
          name: "Banner",
          to: "/components",
          icon: "copy",
          children: [
            { to: "/components/bannerupload", name: "Banner Upload" },
            { to: "/components/bannerlist", name: "Banner List" },
          ],
        }),
        (rssfeedroutes = {
          name: "RSS Feed",
          to: "/components",
          icon: "copy",
          children: [
            { to: "/components/rssfeedadd", name: "RSS Feed Add" },
            { to: "/components/rssfeedlist", name: "RSS Feed List" },
          ],
        }),
      ],
    };
    display = {
      name: "Banner Campaigns",
      to: "/components",
      icon: "image",
      children: [
        (displayCampaignRoutes = {
          name: "Campaign Manage",
          to: "/components",
          icon: "play",
          children: [
            { to: "/components/displayCampaignList", name: "List" },
            { to: "/components/displayCampaign/Report", name: "Report" },
          ],
        }),
        (displayDemandRoutes = {
          name: "Demand Manage",
          to: "/components",
          icon: "image",
          children: [
            {
              to: "/components/displayDemandSourceList",
              name: "Demand List",
            },
            { to: "/displayDemand/mapping", name: "Demand Mapping" },
            { to: "/components/displayDemandReport", name: "Demand Report" },
            {
              to: "/components/displayrevenueanalyticsreport",
              name: "Revenue Analytics ",
            },
          ],
        }),
      ],
    };

     videoAnalyticsReport = {
      name: "Video Analytics Report",
      to: "/components/videoAnalyticsReport",
      icon: "poll",
    };

    demographicRoutes = {
      name: "Demographic Report",
      to: "/components/demographic/report",
      icon: "chart-area"

    }

    reconciliationRoutes = {
      name: "Reconciliation",
      to: "/components/Reconciliation", 
      icon:"balance-scale-left",
    }

  } else if (user.role === 1) {
    homeRoutes = {
      name: "Home",
      to: "/",
      exact: true,
      icon: "home",
      children: [
        {
          to: "/",
          name: "Dashboard",
          icon: "chart-pie",
          exact: true,
        },
      ],
    };

    videoRoutes = {
      name: "Videos",
      to: "/components",
      icon: "video",
      children: [
        { to: "/videos", name: "List" },
      ],
    };

    deviceRoutes = {
      name: "Devices",
      to: "/components",
      icon: "tv",
      children: [
        { to: "/components/deviceList", name: "List" },
        { to: "/components/deviceAdd", name: "Add New" },
        { to: "/components/device/enquiry", name: "Inquiry"}
      ],
    };

    playlistRoutes = {
      name: "Playlist",
      to: "/components",
      icon: "list",
      children: [
        {to: "/playlists", name : "List"},
        { to: "/playlists/add", name: "Add New" },
      ],
    };
    campaignRoutes = {
      name: "Video Campaigns",
      to: "/components",
      icon: "play",
      children: [
        { to: "/components/campaignList", name: "List" },
        { to: "/components/campaignReport", name: "Report" },
        (advertiserroutes = {
          name: "Advertiser",
          to: "/components",
          icon: "lock",
          children: [
            {
              to: "/components/CampaignPaymentAdmn",
              name: "Advertiser Payment",
            },
            {
              to: "/components/advertiserstatement",
              name: "Advertiser Statement",
            },
            { to: "/components/paymentReport", name: "Payment Details" },
          ],
        }),
      ],
    };

    creativeManagementRoutes = {
      name :"Creative Management",
      to : "/components",
      icon : "photo-video",
      children : [
        {to : "/components/creativeManageList", name : "List"},
        {to : "/components/creativeManageAdd", name : "Add New"},
      ],
    }

    superReportRoutes = {
      name: "Revenue Report",
      to: "/components/superReport",
      icon: "user-tie",
    };

    outagereportroutes = {
      name: "Outage Report",
      to: "/components/outagereport",
      icon: "lock",
    };
    merchantRoutes = {
      name: "Merchant Statement",
      to: "/components/merchantStatement",
      icon: "copy",
    };

    monthlyRoutes = {
      name: "Monthly Statement",
      to: "/components/monthlyStatement",
      icon: "copy",
    };

    userNameRoutes = {
      name: ORGANIZATION_LABEL,
      to: "/components",
      icon: "cubes",
      children: [
        { to: "/components/userNameList", name: "List" },
        { to: "/components/userNameAdd", name: "Add New" },
      ],
    };

    locationRoutes = {
      name: "Locations",
      to: "/components",
      icon: "map-marked",
      children: [
        { to: "/components/locationList", name: "List" },
        { to: "/components/locationAdd", name: "Add New" },
      ],
    };

    userManagementRoutes = {
      name: "User Manage",
      to: "/components",
      icon: "book",
      children: [
        { to: "/components/clientList", name: "List" },
        { to: "/components/clientAdd", name: "Add User" },
      ],
    };

    userDetailsRoutes = {
      name: "User Manage",
      to: "/components",
      icon: "users-cog",
      children: [
        { to: "/components/userList", name: "List" },
        { to: "/components/userAdd", name: "Add User" },
      ],
    };

    settingsRoutes = {
      name: "Settings",
      to: "/components",
      icon: "code-branch",
      children: [
        { to: "/components/clientList", name: "Reset Password" },
        { to: "/components/clientAdd", name: "Offline Video" },
      ],
    };

    authenticationRoutes = {
      name: "Authentication",
      to: "/authentication",
      icon: "lock",
      children: [
        {
          to: "/authentication/basic",
          name: "Basic",
          children: [
            { to: "/authentication/basic/login", name: "Login" },
            { to: "/authentication/basic/logout", name: "Logout" },
            { to: "/authentication/basic/register", name: "Register" },
            {
              to: "/authentication/basic/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/basic/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/basic/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/basic/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/card",
          name: "Card",
          children: [
            { to: "/authentication/card/login", name: "Login" },
            { to: "/authentication/card/logout", name: "Logout" },
            { to: "/authentication/card/register", name: "Register" },
            {
              to: "/authentication/card/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/card/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/card/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/card/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/split",
          name: "Split",
          children: [
            { to: "/authentication/split/login", name: "Login" },
            { to: "/authentication/split/logout", name: "Logout" },
            { to: "/authentication/split/register", name: "Register" },
            {
              to: "/authentication/split/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/split/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/split/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/split/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/wizard",
          name: "Wizard",
        },
      ],
    };

    ECommerceRoutes = {
      name: "E commerce",
      to: "/e-commerce",
      icon: "cart-plus",
      children: [
        { to: "/e-commerce/products/list", name: "Product list" },
        { to: "/e-commerce/products/grid", name: "Product grid" },
        { to: "/e-commerce/product-details", name: "Product details" },
        { to: "/e-commerce/orders", name: "Orders" },
        { to: "/e-commerce/order-details", name: "Order details" },
        { to: "/e-commerce/customers", name: "Customers" },
        { to: "/e-commerce/shopping-cart", name: "Shopping cart" },
        { to: "/e-commerce/checkout", name: "Checkout" },
        { to: "/e-commerce/favourite-items", name: "Favourite items" },
      ],
    };

    pageRoutes = {
      name: "Pages",
      to: "/pages",
      icon: "copy",
      children: [
        { to: "/pages/activity", name: "Activity" },
        { to: "/pages/associations", name: "Associations" },
        { to: "/pages/billing", name: "Billing" },
        { to: "/pages/customer-details", name: "Customer details" },
        { to: "/pages/event-detail", name: "Event detail" },
        { to: "/pages/event-create", name: "Event create" },
        { to: "/pages/events", name: "Events" },
        { to: "/pages/faq", name: "Faq" },
        { to: "/pages/invoice", name: "Invoice" },
        { to: "/pages/invite-people", name: "Invite people" },
        { to: "/pages/notifications", name: "Notifications" },
        { to: "/pages/people", name: "People" },
        { to: "/pages/pricing", name: "Pricing" },
        { to: "/pages/pricing-alt", name: "Pricing alt" },
        { to: "/pages/profile", name: "Profile" },
        { to: "/pages/settings", name: "Settings" },
        { to: "/pages/starter", name: "Starter" },
        {
          to: "/errors",
          name: "Errors",
          children: [
            { to: "/errors/404", name: "404" },
            { to: "/errors/500", name: "500" },
          ],
        },
      ],
    };
    widgetsRoutes = {
      name: "Widgets",
      to: "/widgets",
      exact: true,
      icon: "poll",
      badge: {
        text: `New`,
        color: "soft-success",
      },
    };

    chatRoutes = {
      name: "Chat",
      to: "/chat",
      exact: true,
      icon: "comments",
    };

    emailRoutes = {
      name: "Email",
      to: "/email",
      icon: "envelope-open",
      children: [
        { to: "/email/inbox", name: "Inbox" },
        { to: "/email/email-detail", name: "Email detail" },
        { to: "/email/compose", name: "Compose" },
      ],
    };

    documentationRoutes = {
      name: "Documentation",
      to: "/documentation",
      exact: true,
      icon: "book",
    };

    changelogRoutes = {
      name: "ChangeLog",
      to: "/changelog",
      exact: true,
      icon: "code-branch",
      badge: {
        text: `v${version}`,
        color: "soft-primary",
      },
    };

    componentRoutes = {
      name: "Clients",
      to: "/components",
      icon: "puzzle-piece",
      children: [
        { to: "/components/alerts", name: "Alerts" },
        { to: "/components/accordions", name: "Accordions" },
        { to: "/components/avatar", name: "Avatar" },
        { to: "/components/badges", name: "Badges" },
        { to: "/components/backgrounds", name: "Backgrounds" },
        { to: "/components/breadcrumb", name: "Breadcrumb" },
        { to: "/components/buttons", name: "Buttons" },
        { to: "/components/cards", name: "Cards" },
        { to: "/components/collapses", name: "Collapses" },
        {
          to: "/components/carousel",
          name: "Carousel",
          badge: {
            text: `New`,
          },
        },
        { to: "/components/dropdowns", name: "Dropdowns" },
        { to: "/components/forms", name: "Forms" },
        { to: "/components/listgroups", name: "List groups" },
        { to: "/components/modals", name: "Modals" },
        { to: "/components/navs", name: "Navs" },
        { to: "/components/navbars", name: "Navbars" },
        {
          to: "/components/navbar-top",
          name: "Navbar Top",
          badge: {
            text: `New`,
          },
        },
        { to: "/components/pageheaders", name: "Page headers" },
        { to: "/components/paginations", name: "Paginations" },
        { to: "/components/popovers", name: "Popovers" },
        { to: "/components/progress", name: "Progress" },
        {
          to: "/components/sidepanel",
          name: "Sidepanel",
          badge: {
            text: "New",
          },
        },
        {
          to: "/components/spinners",
          name: "Spinners",
          badge: {
            text: `New`,
          },
        },

        { to: "/components/tables", name: "Tables" },
        { to: "/components/clientList", name: "Client List" },
        { to: "/components/clientAdd", name: "Client Add" },
        { to: "/components/clientEdit", name: "Client Edit" },
        { to: "/components/tooltips", name: "Tooltips" },
      ],
    };
    pluginRoutes = {
      name: "Plugins",
      to: "/plugins",
      icon: "plug",
      children: [
        { to: "/plugins/bulk-select", name: "Bulk select" },
        {
          to: "/plugins",
          name: "Chart",
          children: [
            { to: "/plugins/chart", name: "Chart Js" },
            { to: "/plugins/echarts", name: "Echarts" },
          ],
        },
        { to: "/plugins/countup", name: "Countup" },
        { to: "/plugins/code-highlight", name: "Code Highlight" },

        { to: "/plugins/datetime", name: "Datetime" },
        { to: "/plugins/dropzone", name: "Dropzone" },
        { to: "/plugins/emoji-mart", name: "Emoji Mart" },
        { to: "/plugins/fontawesome", name: "Fontawesome" },

        { to: "/plugins/image-lightbox", name: "Image lightbox" },
        { to: "/plugins/lottie", name: "Lottie" },
        {
          to: "/plugins",
          name: "Map",
          children: [
            { to: "/plugins/leaflet-map", name: "Leaflet map" },
            { to: "/plugins/google-map", name: "Google map" },
            { to: "/plugins/echart-map", name: "Echart Map" },
          ],
        },
        { to: "/plugins/plyr", name: "Plyr" },
        { to: "/plugins/progressbar", name: "Progressbar" },
        { to: "/plugins/react-hook-form", name: "React Hook Form" },
        { to: "/plugins/select", name: "Select" },
        { to: "/plugins/slick-carousel", name: "Slick Carousel" },
        { to: "/plugins/scroll-bar", name: "Scroll Bar" },
        { to: "/plugins/toastify", name: "Toastify" },
        { to: "/plugins/typed", name: "Typed" },
        { to: "/plugins/wysiwyg", name: "WYSIWYG editor" },
      ],
    };

    utilityRoutes = {
      name: "Utilities",
      to: "/utilities",
      icon: ["fab", "hotjar"],
      children: [
        { to: "/utilities/borders", name: "Borders" },
        { to: "/utilities/clearfix", name: "Clearfix" },
        { to: "/utilities/closeIcon", name: "Close icon" },
        { to: "/utilities/colors", name: "Colors" },
        { to: "/utilities/display", name: "Display" },
        { to: "/utilities/embed", name: "Embed" },
        { to: "/utilities/figures", name: "Figures" },
        { to: "/utilities/flex", name: "Flex" },
        { to: "/utilities/grid", name: "Grid" },
        { to: "/utilities/sizing", name: "Sizing" },
        { to: "/utilities/spacing", name: "Spacing" },
        { to: "/utilities/stretchedLink", name: "Stretched link" },
        { to: "/utilities/typography", name: "Typography" },
        { to: "/utilities/verticalAlign", name: "Vertical align" },
        { to: "/utilities/visibility", name: "Visibility" },
      ],
    };
    display = {
      name: "Banner Campaigns",
      to: "/components",
      icon: "image",
      children: [
        (displayCampaignRoutes = {
          name: "Campaign Manage",
          to: "/components",
          icon: "play",
          children: [
            { to: "/components/displayCampaignList", name: "List" },
            { to: "/components/displayCampaign/Report", name: "Report" },
          ],
        }),
      ],
    };
    videoAnalyticsReport = {
      name: "Video Analytics Report",
      to: "/components/videoAnalyticsReport",
      icon: "poll",
    };
  
    
  } else if (user.role === 10) {
    homeRoutes = {
      name: "Home",
      to: "/",
      exact: true,
      icon: "home",
      children: [
        {
          to: "/",
          name: "Dashboard",
          icon: "chart-pie",
          exact: true,
        },
      ],
    };

    videoRoutes = {
      name: "Videos",
      to: "/components",
      icon: "video",
      children: [
        { to: "/videos", name: "List" },
      ],
    };

    deviceRoutes = {
      name: "Devices",
      to: "/components",
      icon: "tv",
      children: [
        { to: "/components/deviceList", name: "List" },
        { to: "/components/deviceAdd", name: "Add New" },
        { to: "/components/device/enquiry", name: "Inquiry"}
      ],
    };

    playlistRoutes = {
      name: "Playlist",
      to: "/components",
      icon: "list",
      children: [
        {to: "/playlists", name : "List"},
        { to: "/playlists/add", name: "Add New" },
      ],
    };

    superReportRoutes = {
      name: "Report",
      to: "/components/superReport",
      icon: "user-tie",
    };

    outagereportroutes = {
      name: "Outage Report",
      to: "/components/outagereport",
      icon: "lock",
    };

    monthlyRoutes = {
      name: "Monthly Statement",
      to: "/components/monthlyStatement",
      icon: "copy",
    };

    locationRoutes = {
      name: "Locations",
      to: "/components",
      icon: "map-marked",
      children: [
        { to: "/components/locationList", name: "List" },
        { to: "/components/locationAdd", name: "Add New" },
      ],
    };

    userManagementRoutes = {
      name: "User Manage",
      to: "/components",
      icon: "book",
      children: [
        { to: "/components/clientList", name: "List" },
        { to: "/components/clientAdd", name: "Add User" },
      ],
    };

    userDetailsRoutes = {
      name: "User Manage",
      to: "/components",
      icon: "users-cog",
      children: [
        { to: "/components/userList", name: "List" },
        { to: "/components/userAdd", name: "Add User" },
      ],
    };

    settingsRoutes = {
      name: "Settings",
      to: "/components",
      icon: "code-branch",
      children: [
        { to: "/components/clientList", name: "Reset Password" },
        { to: "/components/clientAdd", name: "Offline Video" },
      ],
    };

    importRoutes = {
      name: "Import Data",
      to: "/components/clientList",
      icon: "lock",
    };

    authenticationRoutes = {
      name: "Authentication",
      to: "/authentication",
      icon: "lock",
      children: [
        {
          to: "/authentication/basic",
          name: "Basic",
          children: [
            { to: "/authentication/basic/login", name: "Login" },
            { to: "/authentication/basic/logout", name: "Logout" },
            { to: "/authentication/basic/register", name: "Register" },
            {
              to: "/authentication/basic/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/basic/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/basic/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/basic/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/card",
          name: "Card",
          children: [
            { to: "/authentication/card/login", name: "Login" },
            { to: "/authentication/card/logout", name: "Logout" },
            { to: "/authentication/card/register", name: "Register" },
            {
              to: "/authentication/card/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/card/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/card/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/card/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/split",
          name: "Split",
          children: [
            { to: "/authentication/split/login", name: "Login" },
            { to: "/authentication/split/logout", name: "Logout" },
            { to: "/authentication/split/register", name: "Register" },
            {
              to: "/authentication/split/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/split/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/split/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/split/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/wizard",
          name: "Wizard",
        },
      ],
    };

    ECommerceRoutes = {
      name: "E commerce",
      to: "/e-commerce",
      icon: "cart-plus",
      children: [
        { to: "/e-commerce/products/list", name: "Product list" },
        { to: "/e-commerce/products/grid", name: "Product grid" },
        { to: "/e-commerce/product-details", name: "Product details" },
        { to: "/e-commerce/orders", name: "Orders" },
        { to: "/e-commerce/order-details", name: "Order details" },
        { to: "/e-commerce/customers", name: "Customers" },
        { to: "/e-commerce/shopping-cart", name: "Shopping cart" },
        { to: "/e-commerce/checkout", name: "Checkout" },
        { to: "/e-commerce/favourite-items", name: "Favourite items" },
      ],
    };

    pageRoutes = {
      name: "Pages",
      to: "/pages",
      icon: "copy",
      children: [
        { to: "/pages/activity", name: "Activity" },
        { to: "/pages/associations", name: "Associations" },
        { to: "/pages/billing", name: "Billing" },
        { to: "/pages/customer-details", name: "Customer details" },
        { to: "/pages/event-detail", name: "Event detail" },
        { to: "/pages/event-create", name: "Event create" },
        { to: "/pages/events", name: "Events" },
        { to: "/pages/faq", name: "Faq" },
        { to: "/pages/invoice", name: "Invoice" },
        { to: "/pages/invite-people", name: "Invite people" },
        { to: "/pages/notifications", name: "Notifications" },
        { to: "/pages/people", name: "People" },
        { to: "/pages/pricing", name: "Pricing" },
        { to: "/pages/pricing-alt", name: "Pricing alt" },
        { to: "/pages/profile", name: "Profile" },
        { to: "/pages/settings", name: "Settings" },
        { to: "/pages/starter", name: "Starter" },
        {
          to: "/errors",
          name: "Errors",
          children: [
            { to: "/errors/404", name: "404" },
            { to: "/errors/500", name: "500" },
          ],
        },
      ],
    };
    widgetsRoutes = {
      name: "Widgets",
      to: "/widgets",
      exact: true,
      icon: "poll",
      badge: {
        text: `New`,
        color: "soft-success",
      },
    };

    chatRoutes = {
      name: "Chat",
      to: "/chat",
      exact: true,
      icon: "comments",
    };

    emailRoutes = {
      name: "Email",
      to: "/email",
      icon: "envelope-open",
      children: [
        { to: "/email/inbox", name: "Inbox" },
        { to: "/email/email-detail", name: "Email detail" },
        { to: "/email/compose", name: "Compose" },
      ],
    };

    documentationRoutes = {
      name: "Documentation",
      to: "/documentation",
      exact: true,
      icon: "book",
    };

    changelogRoutes = {
      name: "ChangeLog",
      to: "/changelog",
      exact: true,
      icon: "code-branch",
      badge: {
        text: `v${version}`,
        color: "soft-primary",
      },
    };

    componentRoutes = {
      name: "Clients",
      to: "/components",
      icon: "puzzle-piece",
      children: [
        { to: "/components/alerts", name: "Alerts" },
        { to: "/components/accordions", name: "Accordions" },
        { to: "/components/avatar", name: "Avatar" },
        { to: "/components/badges", name: "Badges" },
        { to: "/components/backgrounds", name: "Backgrounds" },
        { to: "/components/breadcrumb", name: "Breadcrumb" },
        { to: "/components/buttons", name: "Buttons" },
        { to: "/components/cards", name: "Cards" },
        { to: "/components/collapses", name: "Collapses" },
        {
          to: "/components/carousel",
          name: "Carousel",
          badge: {
            text: `New`,
          },
        },
        { to: "/components/dropdowns", name: "Dropdowns" },
        { to: "/components/forms", name: "Forms" },
        { to: "/components/listgroups", name: "List groups" },
        { to: "/components/modals", name: "Modals" },
        { to: "/components/navs", name: "Navs" },
        { to: "/components/navbars", name: "Navbars" },
        {
          to: "/components/navbar-top",
          name: "Navbar Top",
          badge: {
            text: `New`,
          },
        },
        { to: "/components/pageheaders", name: "Page headers" },
        { to: "/components/paginations", name: "Paginations" },
        { to: "/components/popovers", name: "Popovers" },
        { to: "/components/progress", name: "Progress" },
        {
          to: "/components/sidepanel",
          name: "Sidepanel",
          badge: {
            text: "New",
          },
        },
        {
          to: "/components/spinners",
          name: "Spinners",
          badge: {
            text: `New`,
          },
        },

        { to: "/components/tables", name: "Tables" },
        { to: "/components/clientList", name: "Client List" },
        { to: "/components/clientAdd", name: "Client Add" },
        { to: "/components/clientEdit", name: "Client Edit" },
        { to: "/components/tooltips", name: "Tooltips" },
      ],
    };
    pluginRoutes = {
      name: "Plugins",
      to: "/plugins",
      icon: "plug",
      children: [
        { to: "/plugins/bulk-select", name: "Bulk select" },
        {
          to: "/plugins",
          name: "Chart",
          children: [
            { to: "/plugins/chart", name: "Chart Js" },
            { to: "/plugins/echarts", name: "Echarts" },
          ],
        },
        { to: "/plugins/countup", name: "Countup" },
        { to: "/plugins/code-highlight", name: "Code Highlight" },

        { to: "/plugins/datetime", name: "Datetime" },
        { to: "/plugins/dropzone", name: "Dropzone" },
        { to: "/plugins/emoji-mart", name: "Emoji Mart" },
        { to: "/plugins/fontawesome", name: "Fontawesome" },

        { to: "/plugins/image-lightbox", name: "Image lightbox" },
        { to: "/plugins/lottie", name: "Lottie" },
        {
          to: "/plugins",
          name: "Map",
          children: [
            { to: "/plugins/leaflet-map", name: "Leaflet map" },
            { to: "/plugins/google-map", name: "Google map" },
            { to: "/plugins/echart-map", name: "Echart Map" },
          ],
        },
        { to: "/plugins/plyr", name: "Plyr" },
        { to: "/plugins/progressbar", name: "Progressbar" },
        { to: "/plugins/react-hook-form", name: "React Hook Form" },
        { to: "/plugins/select", name: "Select" },
        { to: "/plugins/slick-carousel", name: "Slick Carousel" },
        { to: "/plugins/scroll-bar", name: "Scroll Bar" },
        { to: "/plugins/toastify", name: "Toastify" },
        { to: "/plugins/typed", name: "Typed" },
        { to: "/plugins/wysiwyg", name: "WYSIWYG editor" },
      ],
    };

    utilityRoutes = {
      name: "Utilities",
      to: "/utilities",
      icon: ["fab", "hotjar"],
      children: [
        { to: "/utilities/borders", name: "Borders" },
        { to: "/utilities/clearfix", name: "Clearfix" },
        { to: "/utilities/closeIcon", name: "Close icon" },
        { to: "/utilities/colors", name: "Colors" },
        { to: "/utilities/display", name: "Display" },
        { to: "/utilities/embed", name: "Embed" },
        { to: "/utilities/figures", name: "Figures" },
        { to: "/utilities/flex", name: "Flex" },
        { to: "/utilities/grid", name: "Grid" },
        { to: "/utilities/sizing", name: "Sizing" },
        { to: "/utilities/spacing", name: "Spacing" },
        { to: "/utilities/stretchedLink", name: "Stretched link" },
        { to: "/utilities/typography", name: "Typography" },
        { to: "/utilities/verticalAlign", name: "Vertical align" },
        { to: "/utilities/visibility", name: "Visibility" },
      ],
    };
    videoAnalyticsReport = {
      name: "Video Analytics Report",
      to: "/components/videoAnalyticsReport",
      icon: "poll",
    };
     
  } else if (user.role === 12) {
    homeRoutes = {
      name: "Home",
      to: "/",
      exact: true,
      icon: "home",
      children: [
        {
          to: "/",
          name: "Dashboard",
          icon: "chart-pie",
          exact: true,
        },
      ],
    };

    videoRoutes = {
      name: "Videos",
      to: "/components",
      icon: "video",
      children: [
        { to: "/videos", name: "List" },
      ],
    };

    deviceRoutes = {
      name: "Devices",
      to: "/components",
      icon: "tv",
      children: [
        { to: "/components/deviceList", name: "List" },
        { to: "/components/deviceAdd", name: "Add New" },
        { to: "/components/device/enquiry", name: "Inquiry"}
      ],
    };

    playlistRoutes = {
      name: "Playlist",
      to: "/components",
      icon: "list",
      children: [
        { to: "/playlists", name: "List" },
        { to: "/playlists/add", name: "Add New" },
      ],
    };

    superReportRoutes = {
      name: "Location Report",
      to: "/components/superReport",
      icon: "user-tie",
    };

    authenticationRoutes = {
      name: "Authentication",
      to: "/authentication",
      icon: "lock",
      children: [
        {
          to: "/authentication/basic",
          name: "Basic",
          children: [
            { to: "/authentication/basic/login", name: "Login" },
            { to: "/authentication/basic/logout", name: "Logout" },
            { to: "/authentication/basic/register", name: "Register" },
            {
              to: "/authentication/basic/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/basic/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/basic/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/basic/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/card",
          name: "Card",
          children: [
            { to: "/authentication/card/login", name: "Login" },
            { to: "/authentication/card/logout", name: "Logout" },
            { to: "/authentication/card/register", name: "Register" },
            {
              to: "/authentication/card/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/card/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/card/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/card/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/split",
          name: "Split",
          children: [
            { to: "/authentication/split/login", name: "Login" },
            { to: "/authentication/split/logout", name: "Logout" },
            { to: "/authentication/split/register", name: "Register" },
            {
              to: "/authentication/split/forget-password",
              name: "Forgot password",
            },
            {
              to: "/authentication/split/password-reset",
              name: "Reset password",
            },
            { to: "/authentication/split/confirm-mail", name: "Confirm mail" },
            { to: "/authentication/split/lock-screen", name: "Lock screen" },
          ],
        },
        {
          to: "/authentication/wizard",
          name: "Wizard",
        },
      ],
    };

    ECommerceRoutes = {
      name: "E commerce",
      to: "/e-commerce",
      icon: "cart-plus",
      children: [
        { to: "/e-commerce/products/list", name: "Product list" },
        { to: "/e-commerce/products/grid", name: "Product grid" },
        { to: "/e-commerce/product-details", name: "Product details" },
        { to: "/e-commerce/orders", name: "Orders" },
        { to: "/e-commerce/order-details", name: "Order details" },
        { to: "/e-commerce/customers", name: "Customers" },
        { to: "/e-commerce/shopping-cart", name: "Shopping cart" },
        { to: "/e-commerce/checkout", name: "Checkout" },
        { to: "/e-commerce/favourite-items", name: "Favourite items" },
      ],
    };

    pageRoutes = {
      name: "Pages",
      to: "/pages",
      icon: "copy",
      children: [
        { to: "/pages/activity", name: "Activity" },
        { to: "/pages/associations", name: "Associations" },
        { to: "/pages/billing", name: "Billing" },
        { to: "/pages/customer-details", name: "Customer details" },
        { to: "/pages/event-detail", name: "Event detail" },
        { to: "/pages/event-create", name: "Event create" },
        { to: "/pages/events", name: "Events" },
        { to: "/pages/faq", name: "Faq" },
        { to: "/pages/invoice", name: "Invoice" },
        { to: "/pages/invite-people", name: "Invite people" },
        { to: "/pages/notifications", name: "Notifications" },
        { to: "/pages/people", name: "People" },
        { to: "/pages/pricing", name: "Pricing" },
        { to: "/pages/pricing-alt", name: "Pricing alt" },
        { to: "/pages/profile", name: "Profile" },
        { to: "/pages/settings", name: "Settings" },
        { to: "/pages/starter", name: "Starter" },
        {
          to: "/errors",
          name: "Errors",
          children: [
            { to: "/errors/404", name: "404" },
            { to: "/errors/500", name: "500" },
          ],
        },
      ],
    };
    widgetsRoutes = {
      name: "Widgets",
      to: "/widgets",
      exact: true,
      icon: "poll",
      badge: {
        text: `New`,
        color: "soft-success",
      },
    };

    chatRoutes = {
      name: "Chat",
      to: "/chat",
      exact: true,
      icon: "comments",
    };

    emailRoutes = {
      name: "Email",
      to: "/email",
      icon: "envelope-open",
      children: [
        { to: "/email/inbox", name: "Inbox" },
        { to: "/email/email-detail", name: "Email detail" },
        { to: "/email/compose", name: "Compose" },
      ],
    };

    documentationRoutes = {
      name: "Documentation",
      to: "/documentation",
      exact: true,
      icon: "book",
    };

    changelogRoutes = {
      name: "ChangeLog",
      to: "/changelog",
      exact: true,
      icon: "code-branch",
      badge: {
        text: `v${version}`,
        color: "soft-primary",
      },
    };

    componentRoutes = {
      name: "Clients",
      to: "/components",
      icon: "puzzle-piece",
      children: [
        { to: "/components/alerts", name: "Alerts" },
        { to: "/components/accordions", name: "Accordions" },
        { to: "/components/avatar", name: "Avatar" },
        { to: "/components/badges", name: "Badges" },
        { to: "/components/backgrounds", name: "Backgrounds" },
        { to: "/components/breadcrumb", name: "Breadcrumb" },
        { to: "/components/buttons", name: "Buttons" },
        { to: "/components/cards", name: "Cards" },
        { to: "/components/collapses", name: "Collapses" },
        {
          to: "/components/carousel",
          name: "Carousel",
          badge: {
            text: `New`,
          },
        },
        { to: "/components/dropdowns", name: "Dropdowns" },
        { to: "/components/forms", name: "Forms" },
        { to: "/components/listgroups", name: "List groups" },
        { to: "/components/modals", name: "Modals" },
        { to: "/components/navs", name: "Navs" },
        { to: "/components/navbars", name: "Navbars" },
        {
          to: "/components/navbar-top",
          name: "Navbar Top",
          badge: {
            text: `New`,
          },
        },
        { to: "/components/pageheaders", name: "Page headers" },
        { to: "/components/paginations", name: "Paginations" },
        { to: "/components/popovers", name: "Popovers" },
        { to: "/components/progress", name: "Progress" },
        {
          to: "/components/sidepanel",
          name: "Sidepanel",
          badge: {
            text: "New",
          },
        },
        {
          to: "/components/spinners",
          name: "Spinners",
          badge: {
            text: `New`,
          },
        },

        { to: "/components/tables", name: "Tables" },
        { to: "/components/clientList", name: "Client List" },
        { to: "/components/clientAdd", name: "Client Add" },
        { to: "/components/clientEdit", name: "Client Edit" },
        { to: "/components/tooltips", name: "Tooltips" },
      ],
    };
    pluginRoutes = {
      name: "Plugins",
      to: "/plugins",
      icon: "plug",
      children: [
        { to: "/plugins/bulk-select", name: "Bulk select" },
        {
          to: "/plugins",
          name: "Chart",
          children: [
            { to: "/plugins/chart", name: "Chart Js" },
            { to: "/plugins/echarts", name: "Echarts" },
          ],
        },
        { to: "/plugins/countup", name: "Countup" },
        { to: "/plugins/code-highlight", name: "Code Highlight" },

        { to: "/plugins/datetime", name: "Datetime" },
        { to: "/plugins/dropzone", name: "Dropzone" },
        { to: "/plugins/emoji-mart", name: "Emoji Mart" },
        { to: "/plugins/fontawesome", name: "Fontawesome" },

        { to: "/plugins/image-lightbox", name: "Image lightbox" },
        { to: "/plugins/lottie", name: "Lottie" },
        {
          to: "/plugins",
          name: "Map",
          children: [
            { to: "/plugins/leaflet-map", name: "Leaflet map" },
            { to: "/plugins/google-map", name: "Google map" },
            { to: "/plugins/echart-map", name: "Echart Map" },
          ],
        },
        { to: "/plugins/plyr", name: "Plyr" },
        { to: "/plugins/progressbar", name: "Progressbar" },
        { to: "/plugins/react-hook-form", name: "React Hook Form" },
        { to: "/plugins/select", name: "Select" },
        { to: "/plugins/slick-carousel", name: "Slick Carousel" },
        { to: "/plugins/scroll-bar", name: "Scroll Bar" },
        { to: "/plugins/toastify", name: "Toastify" },
        { to: "/plugins/typed", name: "Typed" },
        { to: "/plugins/wysiwyg", name: "WYSIWYG editor" },
      ],
    };

    utilityRoutes = {
      name: "Utilities",
      to: "/utilities",
      icon: ["fab", "hotjar"],
      children: [
        { to: "/utilities/borders", name: "Borders" },
        { to: "/utilities/clearfix", name: "Clearfix" },
        { to: "/utilities/closeIcon", name: "Close icon" },
        { to: "/utilities/colors", name: "Colors" },
        { to: "/utilities/display", name: "Display" },
        { to: "/utilities/embed", name: "Embed" },
        { to: "/utilities/figures", name: "Figures" },
        { to: "/utilities/flex", name: "Flex" },
        { to: "/utilities/grid", name: "Grid" },
        { to: "/utilities/sizing", name: "Sizing" },
        { to: "/utilities/spacing", name: "Spacing" },
        { to: "/utilities/stretchedLink", name: "Stretched link" },
        { to: "/utilities/typography", name: "Typography" },
        { to: "/utilities/verticalAlign", name: "Vertical align" },
        { to: "/utilities/visibility", name: "Visibility" },
      ],
    };
  } else if (user.role === 24) {
    homeRoutes = {
      name: "Home",
      to: "/",
      exact: true,
      icon: "home",
      children: [
        {
          to: "/",
          name: "Dashboard",
          icon: "chart-pie",
          exact: true,
        },
      ],
    };

    campaignRoutes = {
      name: "Video Campaigns",
      to: "/components",
      icon: "play",
      children: [
        { to: "/components/campaignReport", name: "Report" },
      ],
    };
    
  } else if (user.role === 25) { // Ad Ops
    homeRoutes = {
      name: "Home",
      to: "/",
      exact: true,
      icon: "home",
      children: [
        {
          to: "/",
          name: "Dashboard",
          icon: "chart-pie",
          exact: true,
        },
      ],
    };

    demandRoutes = {
      name: "Demand Manage",
      to: "/components",
      icon: "ad",
      children: [
        { to: "/components/demandSourceList", name: "Demand List" },
        { to: "/components/demandPartnerList", name: "Partner List" },
        { to: "/demand/mapping", name: "Demand Mapping" },
        { to: "/components/rtb/mapping", name: "RTB Mapping" },
      ],
    };

    superReportRoutes = {
      name: "Super Admin Report",
      to: "/components/superReport",
      icon: "user-tie",
    };

    demandReportRoutes = {
      name: "Demand Report",
      to: "/components/demandReport",
      icon: "poll",
    };

    revenueanalyticsreportroutes = {
      name: "Revenue Analytics ",
      to: "/components/revenueanalyticsreport",
      icon: "file-invoice-dollar",
    };

    outagereportroutes = {
      name: "Outage Report",
      to: "/components/outagereport",
      icon: "lock",
    };

    locationRoutes = {
      name: "Locations",
      to: "/components",
      icon: "map-marked",
      children: [
        { to: "/components/locationList", name: "List" },
        { to: "/components/locationAdd", name: "Add New" },
      ],
    };

    deviceRoutes = {
      name: "Devices",
      to: "/components",
      icon: "tv",
      children: [
        { to: "/components/deviceList", name: "List" },
        { to: "/components/deviceAdd", name: "Add New" },
        { to: "/components/device/enquiry", name: "Inquiry"}
      ],
    };

  } else if (user.role === 27){ // Campaign manager
    campaignRoutes = {
      name: "Video Campaigns",
      to: "/components",
      icon: "play",
      children: [
        { to: "/components/campaignList", name: "List" },
        { to: "/components/campaignReport", name: "Report" },
      ],
    };

    creativeManagementRoutes = {
      name :"Creative Management",
      to : "/components",
      icon : "photo-video",
      children : [
        {to : "/components/creativeManageList", name : "List"},
        {to : "/components/creativeManageAdd", name : "Add New"},
      ],
    }
  }
}

const removeInquiryRoute = (routes) => {
  return routes.map(route => {
    if (route.name === "Devices") {
      return {
        ...route,
        children: route.children.filter(child => child.name !== "Inquiry")
      };
    }
    return route;
  });
};

let routes;
if (user) {
  if (user.role === 26 && user.user_id === 1154){
    routes = [
      homeRoutes,
      deviceRoutes,
      clientRoutes,
      demandRoutes,
      demandSourceRoutes,
      campaignRoutes,
      creativeManagementRoutes,
      placeExchangeLocationSearch,
      superReportRoutes,
      revenueanalyticsreportroutes,
      merchantRoutes,
      demandReportRoutes,
      userNameRoutes,
      locationRoutes,
      userDetailsRoutes,
    ];
  } else if (user.role === 26) {
    routes = [
      homeRoutes,
      deviceRoutes,
      clientRoutes,
      demandRoutes,
      demandSourceRoutes,
      campaignRoutes,
      creativeManagementRoutes,
      placeExchangeLocationSearch,
      display,
      superReportRoutes,
      revenueanalyticsreportroutes,
      merchantRoutes,
      demandReportRoutes,
      demographicRoutes,
      userNameRoutes,
      locationRoutes,
      userDetailsRoutes,
      outagereportroutes,
      partnerdetailsroutes,
      integrationroutes,
      healthreportroutes,
      templateroutes,
      videoAnalyticsReport,
      reconciliationRoutes,
      ServiceConfigurationRoutes,
    ];
  } else if (user.role === 1) {
    let baseRoutes = [
      homeRoutes,
      deviceRoutes,
      outageRoutes,
      videoRoutes,
      playlistRoutes,
    ];
    
    if (user.client_id === 94) {
      userNameRoutes.name = "Networks";
      userDetailsRoutes.name = "Users";
      videoAnalyticsReport.name = "Cached Video Analytics";
      baseRoutes = removeInquiryRoute(baseRoutes).concat([
        superReportRoutes,
        userNameRoutes,
        locationRoutes,
        userDetailsRoutes,
        videoAnalyticsReport
      ]);
    } else {
      baseRoutes = baseRoutes.concat([
        campaignRoutes,
        creativeManagementRoutes,
        display,
        superReportRoutes,
        merchantRoutes,
        monthlyRoutes,
        userNameRoutes,
        locationRoutes,
        userDetailsRoutes,
        outagereportroutes,
        videoAnalyticsReport
      ]);
    }
    routes = baseRoutes;
  } else if (user.role === 10) {
    let baseRoutes = [
      homeRoutes,
      deviceRoutes,
      videoRoutes,
      playlistRoutes,
    ];   
    if (user.client_id === 94) {
      userDetailsRoutes.name = "Users";
      videoAnalyticsReport.name = "Cached Video Analytics";
      baseRoutes = removeInquiryRoute(baseRoutes).concat([
        superReportRoutes,
        locationRoutes,
        userDetailsRoutes,
        videoAnalyticsReport
      ]);
    } else {
      baseRoutes = baseRoutes.concat([
        superReportRoutes,
        monthlyRoutes,
        locationRoutes,
        userDetailsRoutes,
        outagereportroutes,
        videoAnalyticsReport
      ]);
    }
    routes = baseRoutes;
  } else if (user.role === 12) {
    let baseRoutes = [
      homeRoutes,
      deviceRoutes,
    ];  
    if (user.client_id === 94) {
      baseRoutes = removeInquiryRoute(baseRoutes).concat([
        superReportRoutes,
      ]);
    } else {
      baseRoutes = baseRoutes.concat([
        superReportRoutes,
      ]);
    }
    routes = baseRoutes;
  } else if (user.role === 24) {
    routes = [
      advertiser_id !== "56" && homeRoutes,
      campaignRoutes,
    ];
  } else if (user.role === 25) { // Ad Ops
    routes = [
      homeRoutes,
      locationRoutes,
      deviceRoutes,
      demandRoutes,
      superReportRoutes,
      demandReportRoutes,
      revenueanalyticsreportroutes,
      outagereportroutes
    ];
  } else if (user.role === 27){ // Campaign manager
    routes =[
    campaignRoutes,
    creativeManagementRoutes
    ];
  }
}
export default routes;
