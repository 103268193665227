import React, { Fragment, useEffect, useState } from "react";
import { ModalBody, Modal, Row, Col, Card, CardBody, Button } from "reactstrap";
import CardSummary from "./CardSummary";
import ActiveUsersBarChart from "./ActiveUsersBarChart";
import PaymentsLineChart from "./PaymentsLineChart";
import { toast } from "react-toastify";
import moment from "moment";
import loadable from "@loadable/component";
import { service } from "../../network/service";
import { service as superGraphService } from "../../network/SuperGraph/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { ORGANIZATION_LABEL } from "../bootstrap-components/GlobalVariables/globalvariable";

const PurchasesTable = loadable(() => import("./PurchasesTable"));
const ActiveUsersMap = loadable(() => import("./ActiveUsersMap"));
const Dashboard = () => {
  // State
  const [isSelected, setIsSelected] = useState(false);
  const [clientCount, setClientCount] = useState(0);
  const [organizationCount, setOrganizationCount] = useState(0);
  const [locationCount, setLocationCount] = useState(0);
  const [deviceCount, setDeviceCount] = useState(0);
  const [inactiveDeviceCount, setInactiveDeviceCount] = useState(0);
  const [activeDeviceDetails, setActiveDeviceDetails] = useState([]);
  let [client, setClient] = useState([]);

  const [requestCount, setRequestCount] = useState(0);
  const [impressionCount, setImpressionCount] = useState(0);
  const [fillrateCount, setFillrateCount] = useState(0);
  const [revenueCount, setRevenueCount] = useState(0);

  const [firstTitle, setFirstTitle] = useState("Clients");
  const [secTitle, setSecTitle] = useState(ORGANIZATION_LABEL);
  const [thirdTitle, setThirdTitle] = useState("Locations");
  const [fourthTitle, setFourthTitle] = useState("Active Devices");
  const [collapseOne, collapseOneOpen] = useState(false);
  const [showActiveDevice, setShowActiveDevice] = useState(true);

  const [HTMLPlayerCount, setHTMLPlayerCount] = useState(0);

  const history = useHistory();

  useEffect(() => {
    let deviceCount = 0;
    if (localStorage.getItem("role_id") == 24) {
      setShowActiveDevice(false);
    }

    toast(
      <Fragment>
        Welcome to <strong>Perpetual</strong>!<br />
      </Fragment>
    );

    let roleid = localStorage.getItem("role_id");
    let graph_organization_id = localStorage.getItem("org_id");
    let graph_client_id = localStorage.getItem("client_id");
    let graph_location_id = localStorage.getItem("location_id");

    let currentDay = moment(new Date().toLocaleDateString("en-US")).format(
      "YYYY-MM-DD"
    );

    switch (roleid) {
      case 1: // client login
        graph_client_id = graph_client_id;
        graph_organization_id = "";
        graph_location_id = "";
        break;
      case 10: // organization login
        graph_client_id = graph_client_id;
        graph_organization_id = graph_organization_id;
        graph_location_id = "";
        break;
      case 12: //location login
        graph_client_id = graph_client_id;
        graph_organization_id = "";
        graph_location_id = graph_location_id;
        break;
      default:
        break;
    }

    superGraphService
      .getSuperGraph(
        currentDay,
        currentDay,
        graph_client_id,
        "",
        graph_organization_id,
        graph_location_id
      )
      .then((response) => {
        const deviceDetails = response.data;
        if (deviceDetails && deviceDetails.length > 0) {
          // setDeviceCount(deviceDetails[deviceDetails.length - 1].deviceCount);
        }
      });

    if (roleid == 24) {
      setFirstTitle("Ad Views");
      setSecTitle("Total Spend");
      setThirdTitle("Total Budget");
      setFourthTitle("Daily Spend");
      document.getElementById("superData").style.display = "none";
      service.advertiserData().then(
        (response) => {
          console.log("advertresponse", response);
          setClientCount(response.ad_ad_views ? response.ad_ad_views : 0);
          setOrganizationCount(response.total_spent ? response.total_spent : 0);
          setLocationCount(response.total_budget ? response.total_budget : 0);
        },
        (error) => {
          return false;
        }
      );
    } else {
      service.allCount().then(
        (response) => {
          let clientCount = 0;
          let organizationCount = 0;
          let locationCount = 0;
          let deviceCount = 0;
          try {
            clientCount = response.client;
            setClientCount(clientCount > 0 ? clientCount : 1);
            if (response.organization.length > 0) {
              organizationCount = response.organization;
              setOrganizationCount(organizationCount);
            }
            if (response.location.length > 0) {
              locationCount = response.location;
              setLocationCount(locationCount);
            }
            if (response.device.length > 0) {
              deviceCount = response.active;
              let inactiveDevice = response.inActive;
              setDeviceCount(deviceCount);
              setInactiveDeviceCount(inactiveDevice);
            }
          } catch (error) {
            console.log(error);
          }
        },
        (error) => {
          console.log(error);
          return false;
        }
      );
      let activeDevice = [];
      service.activeData().then(
        (response) => {
          if (response && response.data && Array.isArray(response.data)) {
            response.data.map((activeDev, index) => {
              activeDev.lat = activeDev.latitude;
              activeDev.long = activeDev.longitude;
              activeDev.name = activeDev.city;
              activeDev.street = activeDev.city;
              activeDev.location = activeDev.city;
              activeDevice[index] = activeDev;
              deviceCount += activeDev.device_count;
            });
          }
          setActiveDeviceDetails(activeDevice);
        },
        (error) => {
          return false;
        }
      );

      service.clientDataList().then(
        (response) => {
          if (response.status === 100) {
            const datas = response.data;
            setClient(datas);
          }
        },
        (error) => {
          return false;
        }
      );
    }
    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let from = JSON.stringify(today);
    from = from.slice(1, 11);
    let to = JSON.stringify(tomorrow);
    to = to.slice(1, 11);
    var loc = "";
    var mon = "";
    var dev_id = "";
    var client_id = "";
    var org_id = "";
    var loc_id = "";
    if (roleid == 1) {
      client_id = localStorage.getItem("client_id");
    } else if (roleid == 10) {
      client_id = localStorage.getItem("client_id");
      org_id = localStorage.getItem("org_id");
    } else if (roleid == 12) {
      client_id = localStorage.getItem("client_id");
      loc_id = localStorage.getItem("location_id");
    }
    if (client_id == 94) {
      setSecTitle("Networks");
    } else {
      setSecTitle(ORGANIZATION_LABEL);
    }
    service
      .getSuper(
        from,
        to,
        loc,
        mon,
        client_id,
        org_id,
        loc_id,
        dev_id,
        false,
        false,
        false
      )
      .then((response) => {
        var total_req = 0;
        var total_imp = 0;
        var total_cpm = 0;
        var total_fillrate = 0;
        response.data.map((item, index) => {
          total_req = total_req + item.totalRequest;
          setRequestCount(
            total_req.toLocaleString(undefined, { maximumFractionDigits: 2 })
          );
          total_imp = total_imp + item.totalImpression;
          setImpressionCount(
            total_imp.toLocaleString(undefined, { maximumFractionDigits: 2 })
          );
          total_cpm = total_cpm + item.cpm;
          setRevenueCount(total_cpm.toFixed(1));
          total_fillrate = (total_imp / total_req) * 100;
          setFillrateCount(total_fillrate.toFixed(1));
        });
      });
  }, []);

  useEffect(() => {
    let userDetails = localStorage.getItem("user");
    try {
      userDetails = JSON.parse(userDetails);
    } catch (error) {
      userDetails = {};
    }

    if ([26].includes(userDetails.role)) {
      let params = {
        role_id: userDetails.role,
        client_id: userDetails.client_id,
        organization_id: userDetails.organization_id,
        location_id: userDetails.location_id,
      };
      service.getHTMLDeviceCount(params).then((response) => {
        if (
          response.success === true &&
          response.data &&
          Array.isArray(response.data)
        ) {
          const count = response.data[0].player_count
            ? response.data[0].player_count
            : 0;
          setHTMLPlayerCount(count);
        } else {
          setHTMLPlayerCount(0);
        }
      });
    } else {
      setHTMLPlayerCount(0);
    }
  }, []);

  let roleid = localStorage.getItem("role_id");
  console.log("11", activeDeviceDetails);
  return (
    <>
      <Fragment>
        {showActiveDevice && <PaymentsLineChart />}

        <div className="card-deck">
          {roleid == "26" || roleid == "25" ? (
            <CardSummary title={firstTitle} color="success">
              {clientCount}
            </CardSummary>
          ) : null}
          {["1", "26", "25", "24"].includes(roleid) ? (
            <CardSummary title={secTitle} color="success">
              {organizationCount}
            </CardSummary>
          ) : null}
          {["1", "26", "25", "10", "24"].includes(roleid) ? (
            <CardSummary title={thirdTitle} color="success">
              {locationCount}
            </CardSummary>
          ) : null}
        </div>

        <div className="card-deck" id="superData">
          {["1", "26", "25", "10", "12", "24"].includes(roleid) ? (
            <CardSummary title={fourthTitle} color="info">
              {deviceCount}
            </CardSummary>
          ) : null}

          {["26"].includes(roleid) ? (
            <CardSummary title="HTML players" color="info">
              {HTMLPlayerCount}
            </CardSummary>
          ) : null}
        </div>

        <div className="card-deck" id="superData">
          <CardSummary title="Requests" color="warning">
            {requestCount}
          </CardSummary>
          <CardSummary title="Impressions" color="info">
            {impressionCount}
          </CardSummary>
          <CardSummary title="Fillrate" color="success">
            {fillrateCount} %
          </CardSummary>
          <CardSummary title="Revenue" color="info">
            ${revenueCount}
          </CardSummary>
        </div>

        {showActiveDevice && ["25", "26"].includes(roleid) && (
          <Card className="mb-3">
            <CardBody className="p-0">
              <PurchasesTable client={client} setIsSelected={setIsSelected} />
            </CardBody>
          </Card>
        )}

        {showActiveDevice && (
          <Row noGutters>
            <Col lg="4" className="pr-lg-2">
              {client && (
                <ActiveUsersBarChart
                  client={client}
                  deviceCount={deviceCount}
                />
              )}
            </Col>
            <Col lg="8" className="pl-lg-2">
              <ActiveUsersMap
                activeDeviceDetails={activeDeviceDetails}
                zoomVal="2.5"
                positionVal="1"
              />
              {/* <Button
                color="falcon-default"
                size="sm"
                onClick={() => collapseOneOpen(!collapseOne)}
              >
                <span className="d-none d-sm-inline-block mr-1">
                  Full Screen
                </span>
                View
                <FontAwesomeIcon icon="chevron-right" className="ml-1 fs--1" />
              </Button> */}

              <Modal
                isOpen={collapseOne}
                toggle={() => collapseOneOpen(!collapseOne)}
                style={{ width: "280%", height: "600px", position: "absolute" }}
              >
                <ModalBody
                  style={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                    width: "280%",
                    height: "600px",
                    position: "absolute",
                  }}
                >
                  <ActiveUsersMap
                    activeDeviceDetails={activeDeviceDetails}
                    zoomVal="3"
                    positionVal="2"
                  />
                  <span
                    style={{
                      background: "#fff",
                      borderRadius: "3px",
                      padding: "5px",
                      paddingTop: "0px",
                      marginLeft: "45%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let currentPath = window.location.pathname;
                      history.replace("/your-empty-route");
                      setTimeout(() => {
                        history.replace(currentPath);
                      }, 0);
                    }}
                  >
                    Back
                  </span>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        )}
      </Fragment>
    </>
  );
};

export default Dashboard;
